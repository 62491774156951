<template>

  <table class="params">
    <thead>
    <tr>
      <th></th>
      <th colspan="2" class="title_top">Array</th>
      <th colspan="2" class="title_top" >Component</th>
    </tr>
    <tr>
      <th></th>
      <th class="title_top">Application</th>
      <th class="title_top">Typical Max</th>
      <th class="title_top">Application</th>
      <th class="title_top" >Typical Max</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td class="title_top">Current (Arms)</td>
      <td>{{ acCurrent }}</td>
      <td>{{ arrayCurrentRating }}</td>
      <td>{{ mlccCurrent }}</td>
      <td >{{ mlccCurrentRating }}</td>
    </tr>
    <tr>
      <td class="title_top">Voltage (Vrms)</td>
      <td>{{ acVoltage }}</td>
      <td >{{ arrayVoltageRating }}</td>
      <td >{{ mlccVoltage }}</td>
      <td >{{ mlccVoltageRating }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import Vue from 'vue';
import {createNamespacedHelpers} from "vuex";

const {mapState, mapGetters, mapActions} = createNamespacedHelpers("pac");
export default Vue.extend({
  computed: {
    ...mapState([
      'acCurrent',
      'acVoltage',
    ]),
    ...mapGetters([
      'getAcVoltage',
    ])
  },
  methods: {
    ...mapActions([
      'setSortOrder'
    ]),
    roundTonF: function (num) {
      return parseFloat(num).toPrecision(3)
    },
    convertToInteger(scientificNotation: number): string {
      // 將科學計數法轉換為正常數字
      const normalNumber: number = scientificNotation * 1e9;

      // 將數字四捨五入到整數
      const roundedValue: number = Math.round(normalNumber);
      return roundedValue.toString();
    }

  },
  props: {
    arrayCurrentRating: {
      type: Number,
      required: true
    },
    mlccCurrent: {
      type: Number,
      required: true
    },
    mlccCurrentRating: {
      type: Number,
      required: true
    },
    arrayVoltageRating: {
      type: Number,
      required: true
    },
    mlccVoltage: {
      type: Number,
      required: true
    },
    mlccVoltageRating: {
      type: Number,
      required: true
    },
    arrayCapacitance: {
      type: Number,
      required: true
    },
  }
})
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}


table tr:last-child {
  //border-bottom: none;
}

.title_top {
  width: 50%;
  background-color: #205193;
  color: white;
  text-align: center;
  padding: 0.625rem 0.625rem 0.625rem 0.625rem;
  font-size: medium;
  border: 0.125rem black solid;
}



table td {
  text-align: left;
  width: 60%;
  text-align: center;
  background-color: hsla(206, 60%, 47%, 0.03);
  padding: 0.313rem 0.313rem 0.313rem 0.313rem;
  font-size: 1.125rem;
  border: 0.188rem white solid;
}

.params {
  margin-top: 2.5rem;
  margin-left: 2.5rem;
  margin-bottom: 1.25rem;
  width: 40%;
}

td:first-child {

}

</style>